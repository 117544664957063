<template>
  <v-dialog
    v-model="getModal('shareProjectModal').isOpen"
    max-width="660"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
  >
    <div class="gray-0 pt-13 px-4 sm:px-7 pb-6">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Share</h5>
        <UiBtn
          fab
          color="gray-0"
          :disabled="loading"
          @click="hideModal"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <v-divider class="mt-5 mb-8"/>

      <SelectUsers
        v-if="usersList.length"
        :users="usersList"
        hide-member-tab
        @onChange="setShareUsers"
        class="mb-5"
      >
        <template #invitedUsersTab>
          Team members
        </template>
      </SelectUsers>

      <ValidationProvider class="mb-2" mode="eager" tag="div"
                          name="note" vid="note" rules="max:500" v-slot="{ errors }">
        <div class="mb-2 text-body font-weight-light gray-100--text">Notes (Optional)</div>
        <v-text-field
          v-model="note"
          dense
          height="35"
          outlined
          hide-details
          placeholder="Type description here..."
          :error="!!errors.length"
        />
        <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
      </ValidationProvider>

      <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
        <UiBtn
          outlined
          width="146"
          color="gray-60"
          :disabled="loading"
          @click="hideModal"
          class="mt-4"
        >
          Cancel
        </UiBtn>
        <UiBtn
          width="184"
          color="accent"
          :loading="loading"
          :disabled="!shareUsers.length"
          @click="share"
          class="mt-4"
        >
          <IconSendMessage width="16" class="mr-2"/>
          Share
        </UiBtn>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import SelectUsers from "@/components/common/SelectUsers";

export default {
  name: "ShareProjectModal",
  components: {
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconSendMessage: () => import('@/components/icons/IconSendMessage'),
    UiBtn,
    SelectUsers,
  },
  data() {
    return {
      loading: false,
      note: '',
      usersList: [],
      shareUsers: [],
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
    ]),
  },
  created() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      try {
        const res = await this.$api.user.list();
        this.usersList = res.data.map(user => ({
          ...user,
          user_id: user.id,
          text: `${user.first_name || ''} ${user.last_name || ''}`,
          active: false,
          selected: false,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    setShareUsers(users) {
      const {existingUsers, invitedUsers} = users;
      this.shareUsers = [
        ...invitedUsers.map(user => user.email),
        ...existingUsers.map(user => user.email)
      ];
    },
    async share() {
      this.loading = true;

      try {
        await this.$api.project.bulkShare({
          ids: this.getModal('shareProjectModal').data.projects.map(project => project.id),
          emails: this.shareUsers,
          note: this.note
        });

        this.getModal('shareProjectModal').data.projects.forEach(project => {
          this.$toast.open({
            message: `${project.title} shared`,
            type: 'success',
            position: 'top-right'
          });
        });
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false;
        this.hideModal();
      }
    },
    hideModal() {
      this.$emit('hideModal');
      this.$store.dispatch('closeModal', 'shareProjectModal');
      this.note = '';
    },
  }
}
</script>
