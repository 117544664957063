<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item
        :disabled="!getPermission(project.id).dashboard['can-share-dashboard']"
        @click="shareProject">
        <v-list-item-title class="text-captions-1">
          <IconShare width="14" class="mr-2 gray-60--text"/>
          Share project
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="copyProjectLink">
        <v-list-item-title class="text-captions-1">
          <IconPaperclip width="16" class="mr-2 gray-60--text"/>
          Copy Link
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="project.total_files === 0"
        @click="downloadFile"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download Folder
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!getPermission(project.id).dashboard['can-delete-dashboard']"
        @click="deleteProject">
        <v-list-item-title class="text-captions-1">
          <IconDelete width="14" class="mr-2 gray-60--text"/>
          Delete
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!getPermission(project.id).specification['can-edit-specification']"
        @click="project.showRenameInput = true">
        <v-list-item-title class="text-captions-1">
          <IconPen width="14" class="mr-2 gray-60--text"/>
          Rename
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="project.status === $config.project.status.archived"
        :disabled="!getPermission(project.id).dashboard['can-archive-dashboard']"
        @click="updateProjectStatus('rollback')">
        <v-list-item-title class="text-captions-1">
          <IconArchive width="14" class="mr-2 gray-60--text"/>
          Unarchive
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="project.status !== $config.project.status.archived"
        :disabled="!getPermission(project.id).dashboard['can-archive-dashboard']"
        @click="archiveProject">
        <v-list-item-title class="text-captions-1">
          <IconArchive width="14" class="mr-2 gray-60--text"/>
          Archive
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import {copyTextToClipboard} from "@/utils/helpers";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FilesProjectMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconPaperclip: () => import('@/components/icons/IconPaperclip'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconArchive: () => import('@/components/icons/IconArchive'),

    UiMenu,
    UiBtn
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    projectsList: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
  },
  methods: {
    copyProjectLink() {
      copyTextToClipboard(`${window.location.origin}/files/project/${this.project.id}`);
    },
    compressFileNotification() {
      this.$toast.open({
        message: 'Your files are being compressed',
        type: 'success',
        position: 'top-right'
      });
    },
    async downloadFile() {
      try {
        const files = await this.$api.projectModuleDesign.downloadFiles(this.project.id)

        const fileURL = URL.createObjectURL(new Blob([files]));

        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = `${this.project.title}.zip`;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(fileURL);

        this.$toast.open({
          message: 'Your files are being compressed',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        this.$toast.open({
          message: 'Oops! Something went wrong!',
          type: 'error',
          position: 'top-right'
        });
      }
    },
    shareProject() {
      if (!this.getPermission(this.project.id).dashboard['can-share-dashboard']) return;

      this.$store.dispatch('openModal', {
        modalName: 'shareProjectModal',
        data: {
          projects: [this.project]
        }
      });
    },
    archiveProject() {
      if (!this.getPermission(this.project.id).dashboard['can-archive-dashboard']) return;
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to archive this project?',
          description: 'Archiving this project folder will remove it from active project list. This action is <strong>irreversible</strong>. Continue?',
          confirmBtnText: 'Archive',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            await this.updateProjectStatus('archived')
          }
        }
      });
    },
    deleteProject() {
      if (!this.getPermission(this.project.id).dashboard['can-delete-dashboard']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete this project?',
          description: 'If you delete a project all its files will be archived and can be viewed through the files section',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            this.project.loading = true;

            try {
              await this.$api.project.delete(this.project.id);
              const projects = this.projectsList.filter(project => project.id !== this.project.id);
              this.$emit('updateProjectsList', projects);
            } catch (error) {
              this.project.loading = false;
              console.error(error);
            }
          }
        }
      });
    },
    async updateProjectStatus(status) {
      if (!this.getPermission(this.project.id).dashboard['can-archive-dashboard']) return;

      this.project.loading = true;

      try {
        const res = await this.$api.project.updateStatus(this.project.id, status);
        let projects;
        if (this.filters.status.includes(res.data.status.toString())) {
          projects = this.projectsList.map(project => project.id === res.data.id ? {
            ...project,
            status: res.data.status
          } : project)
        } else {
          projects = this.projectsList.filter(project => project.id !== this.project.id);
        }
        this.$emit('updateProjectsList', projects);
      } catch (error) {
        console.error(error);
      } finally {
        this.project.loading = false;
      }
    },
  },
}
</script>
