<template>
  <div class="bulk-actions d-flex align-center">
    <div class="bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selectedProjects.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1">
        {{ projectsList.length }} Total Projects
        <span class="ml-1 font-weight-semi-bold">({{ projectsList.length }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center">
        <UiBtn outlined color="accent" :disabled="!projectsToDelete.length" @click="deleteProjects">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
        <UiBtn outlined color="accent" class="ml-4" :disabled="!projectsToArchive.length" @click="archiveProjects">
          <IconArchive width="14" class="mr-2"/>
          Archive
        </UiBtn>
        <UiBtn outlined color="accent" class="mx-4" :disabled="!projectsToShare.length" @click="shareProjects">
          <IconShare width="14" class="mr-2"/>
          Share
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'BulkActionsBar',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconShare: () => import('@/components/icons/IconShare'),

    UiBtn,
  },
  props: {
    projectsList: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
    selectedProjects() {
      return this.projectsList.filter(project => project.checked);
    },
    projectsToDelete() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-delete-dashboard']);
    },
    projectsToArchive() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-archive-dashboard'] && project.status !== this.$config.project.status.archived);
    },
    projectsToShare() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-share-dashboard']);
    }
  },
  methods: {
    toggleAll(val) {
      const projects = this.projectsList.map(project => ({...project, checked: val}));
      this.$emit('updateProjectsList', projects);
    },
    getLoadingProjectsId(projects) {
      const ids = projects.map(project => project.id);

      const loadingProjects = this.projectsList.map(project => ids.includes(project.id) ? {...project, loading: true} : project);
      this.$emit('updateProjectsList', loadingProjects);

      return ids;
    },
    deleteProjects() {
      if (!this.projectsToDelete.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete these projects?',
          description: 'If you delete these projects all their files will be archived and can be viewed through the files section',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            const ids = this.getLoadingProjectsId(this.projectsToDelete);

            try {
              await this.$api.project.bulkDelete({ids});

              const projects = this.projectsList.reduce((acc, project) => {
                if (!ids.includes(project.id)) {
                  acc.push({...project, loading: false});
                }
                return acc;
              }, []);
              this.$emit('updateProjectsList', projects);
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
    archiveProjects() {
      if (!this.projectsToArchive.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to archive this projects?',
          description: 'Archiving these project folders will remove it from active project list. This action is <strong>irreversible</strong>. Continue?',
          confirmBtnText: 'Archive',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            const ids = this.getLoadingProjectsId(this.projectsToArchive);

            try {
              const res = await this.$api.project.bulkUpdateStatus('archived', ids);
              const projectsToRemove = [];
              const projectsToReplace = res.reduce((acc, project) => {
                if (this.filters.status.includes(project.status.toString())) {
                  acc.push(project);
                } else {
                  projectsToRemove.push(project.id);
                }
                return acc;
              }, []);

              const projects = this.projectsList.reduce((acc, project) => {
                const updatedProject = projectsToReplace.find(rProject => rProject.id === project.id);
                if (updatedProject) {
                  acc.push(updatedProject);
                } else if (!projectsToRemove.includes(project.id)) {
                  acc.push(project);
                }
                return acc;
              }, []);

              this.$emit('updateProjectsList', projects);
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
    shareProjects() {
      if (!this.projectsToShare.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'shareProjectModal',
        data: {
          projects: this.projectsToShare
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.bulk-actions {
  .bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>
